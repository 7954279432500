import { FC, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { isSameDay, nextMonday } from 'date-fns'
import { useWindowSize } from 'rooks'
import Skeleton from 'react-loading-skeleton'

import Alert from 'src/components/01-atoms/Alert'
import InlineLink from 'src/components/01-atoms/InlineLink'
import Container from 'src/components/01-atoms/Container'
import Grid from 'src/components/01-atoms/Grid'
import Card from 'src/components/01-atoms/Card'
import MonthYearSelector from 'src/components/01-atoms/MonthYearSelector'
import Calendar from 'src/components/02-molecules/Calendar'
import CalendarNav from 'src/components/02-molecules/CalendarNav'
import MOTDCard from 'src/components/02-molecules/MOTDCard'
import ToggleGroup from 'src/components/02-molecules/ToggleGroup'
import CalendarPopover from 'src/components/03-organisms/CalendarPopover'

import useAppParams from 'src/utils/hooks/useAppParams'
import {
  dateForManifest,
  getNextDay,
  getNextMonth,
  getPrevDay,
  getPrevMonth,
  getPrevWeek,
} from 'src/utils/helpers/date'
import useGetManifestCalendar from 'src/utils/hooks/useGetManifestCalendar'

import { IManifestAggregate, IManifestCalendarInterval } from 'src/graphql/types'

import CarrierInfo from './CarrierInfo'
import ActionsPending from './ActionsPending'
import MultiFacilityTabs from './MultiFacilityTabs'

interface IManifestHeader {
  labelAggregates: IManifestAggregate[]
  fetchingLabelAggregates?: boolean
  facilityAggregates: IManifestAggregate[]
  fetchingFacilityAggregates?: boolean
}

const ManifestHeader: FC<IManifestHeader> = ({
  labelAggregates,
  fetchingLabelAggregates = false,
  facilityAggregates,
  fetchingFacilityAggregates = false,
}) => {
  const navigate = useNavigate()

  const {
    toShipOn,
    canUseMultiFacility,
    activeFacilities,
    makeLinkUrls,
    withFacilityParams,
    allowSaturdayShipping,
  } = useAppParams()

  const { innerWidth } = useWindowSize()
  const isMobile = innerWidth! < 1024
  const [ selectedDate, setSelectedDate ] = useState( toShipOn )
  const [ interval, setInterval ] = useState<IManifestCalendarInterval>(
    IManifestCalendarInterval.WEEKLY
  )
  const [{ error: calendarError, fetching: fetchingCalendar, data: calendar }] =
    useGetManifestCalendar({
      variables: {
        interval: isMobile ? IManifestCalendarInterval.MONTHLY : interval,
      },
    })

  useEffect(() => {
    if ( isSameDay( toShipOn, selectedDate )) return
    navigate( makeLinkUrls( dateForManifest( selectedDate )).manifest, { replace: true })
  }, [ selectedDate ])

  const dates = calendar?.manifestCalendar
  const hasCachedCalendarData = dates?.some(( x ) => isSameDay( x.toShipOn, toShipOn ))

  const prevOnDesktop =
    interval === IManifestCalendarInterval.MONTHLY ? getPrevMonth( toShipOn ) : getPrevWeek( toShipOn )
  const nextOnDesktop =
    interval === IManifestCalendarInterval.MONTHLY ? getNextMonth( toShipOn ) : nextMonday( toShipOn )

  const prevDate = isMobile ? getPrevDay( toShipOn ) : prevOnDesktop
  const nextDate = isMobile ? getNextDay( toShipOn ) : nextOnDesktop

  const calendarsDisabled = !dates || !hasCachedCalendarData
  const calendarsLoading = fetchingCalendar || !hasCachedCalendarData

  return (
    <div
      className="py-2 bg-gb-gray-200 border-b-2 border-b-gb-gray-400 lg:py-6"
      data-testid="manifest-header"
    >
      <Container>
        <h1 className="font-semibold text-2xl sr-only lg:mb-4 lg:not-sr-only">Orders Overview</h1>
        <div className="grid grid-row grid-cols-3 mb-2 lg:mb-4">
          <CalendarPopover
            dateLinkObject={{
              pathname: makeLinkUrls().manifestBase,
              search: withFacilityParams(),
            }}
            selectedDate={toShipOn}
            dates={dates}
            loading={calendarsLoading}
            disabled={calendarsDisabled}
            handleMonthChange={setSelectedDate}
            className="lg:hidden"
            interval={isMobile ? IManifestCalendarInterval.MONTHLY : interval}
          />
          <ToggleGroup
            className="hidden lg:flex"
            handleChange={setInterval}
            selected={interval}
            buttons={[
              { value: IManifestCalendarInterval.WEEKLY, label: 'Weekly' },
              { value: IManifestCalendarInterval.MONTHLY, label: 'Monthly' },
            ]}
          />
          <div className="center">
            {interval === IManifestCalendarInterval.MONTHLY && (
              <MonthYearSelector
                handleChange={setSelectedDate}
                selectedDate={toShipOn}
                className="hidden min-w-48 lg:inline-flex lg:w-auto"
              />
            )}
          </div>
          <CalendarNav
            prevLink={{
              pathname: makeLinkUrls( dateForManifest( prevDate )).manifest,
              search: withFacilityParams(),
            }}
            todayLink={{
              pathname: makeLinkUrls( dateForManifest( new Date())).manifest,
              search: withFacilityParams(),
            }}
            nextLink={{
              pathname: makeLinkUrls( dateForManifest( nextDate )).manifest,
              search: withFacilityParams(),
            }}
            className="justify-end"
          />
        </div>
        {calendarError ? (
          <Alert type="error">{calendarError.message}</Alert>
        ) : (
          <Calendar
            className="hidden lg:block"
            selectedDate={toShipOn}
            dates={dates}
            dateLinkObject={{
              pathname: makeLinkUrls().manifestBase,
              search: withFacilityParams(),
            }}
            loading={calendarsLoading}
            disabled={calendarsDisabled}
            interval={isMobile ? IManifestCalendarInterval.MONTHLY : interval}
            withSaturdayShipping={allowSaturdayShipping}
          />
        )}
      </Container>
      <div className="border-b-2 border-gb-gray-400 lg:min-h-8 mt-5 mb-4">
        {canUseMultiFacility && (
          <MultiFacilityTabs
            facilities={facilityAggregates}
            activeFacilities={activeFacilities}
            fetching={fetchingFacilityAggregates || fetchingLabelAggregates}
          />
        )}
      </div>
      <Container>
        <Grid className="gap-4 lg:mt-6 min-h-60">
          <MOTDCard
            className="hidden lg:block lg:col-span-3"
            headline="Call For New Products - Q4 2024"
            type="notice"
          >
            <ul className="list-disc ml-4">
              <li>Christmas - submit by 10/1</li>
              <li>Hanukkah - submit by 10/1</li>
              <li>New Year&apos;s Eve - submit by 11/6</li>
            </ul>
            <p>
              <InlineLink
                target="_blank"
                href="https://www.goldbelly.com/merchant_support/sso?origin=https://merchantsupport.goldbelly.com/hc/en-us/articles/20717357112731"
              >
                View All Deadlines
              </InlineLink>{' '}
              and submit your holiday products!
            </p>
          </MOTDCard>

          {labelAggregates ? (
            <ActionsPending
              fetchingAggregates={fetchingLabelAggregates}
              labelAggregates={labelAggregates}
              facilityAggregates={facilityAggregates}
            />
          ) : (
            <Card className="col-span-12 lg:col-span-6 p-0">
              <Skeleton className="items-center" height="100%" width="100%" />
            </Card>
          )}
          <CarrierInfo />
        </Grid>
      </Container>
    </div>
  )
}

export default ManifestHeader
