import { useCallback, useContext, useMemo } from 'react'
import { useLocalstorageState } from 'rooks'
import { Search, useParams, useSearchParams } from 'react-router-dom'
import { isValid, isWeekend, parseISO, startOfDay, nextMonday, isBefore, isSunday } from 'date-fns'

import UserContext from 'src/contexts/UserContext'
import {
  IFacilityType,
  IManifestFilterCategory,
  IManifestPackageSort,
  IManifestTableSortArgument,
} from 'src/graphql/types'

import { dateForManifest } from '../helpers/date'

interface IFacilityInput {
  facilityId?: string | null
  facilityType: IManifestFilterCategory
}

const useAppParams = () => {
  const params = useParams()
  const [ search ] = useSearchParams()
  const [ packageSort, setPackageSort ] = useLocalstorageState<IManifestTableSortArgument>(
    'mdx-manifest-sort-params',
    { sortField: IManifestPackageSort.PRODUCT, sortAscending: true }
  )

  const allowSaturdayShipping = process.env.REACT_APP_ALLOW_SATURDAY_SHIPPING === 'true' || false

  const {
    identity: {
      userId,
      displayPackageBarcode,
      displaySku,
      useDryIcePlaceholder,
      isAdmin,
      isMultiPickUpLocation,
      isMultiShippingFacility,
      isPickUpEnabled,
      shippingFacilities,
      mdashAccountId,
      mdashAccountName,
      mdashAccountPermissions,
      merchantUserPermissions,
      showStatements,
      defaultMerchantId,
      packingSlipVersion,
      reportLinks,
    },
  } = useContext( UserContext )

  const canUseMultiFacility = !!( isMultiPickUpLocation || isMultiShippingFacility )

  const facilityId = search.get( 'facility_id' )
  const facilityType =
    ( search.get( 'facility_type' ) as IFacilityType ) ??
    ( isMultiPickUpLocation ? IFacilityType.PICK_UP_LOCATION : IFacilityType.SHIPPING_FACILITY )
  const isTangibleFacility = Number( facilityId || 0 ) > 0

  const facilityExistsInURL = !!( facilityId && facilityType )
  const pauseForFacility = !( facilityId === null || facilityExistsInURL ) && canUseMultiFacility

  const parsedShipOn = useMemo(() => parseISO( params?.toShipOn! ), [ params.toShipOn ])
  const startOfShipOn = useMemo(
    () => startOfDay( isValid( parsedShipOn ) ? parsedShipOn : new Date()),
    [ parsedShipOn ]
  )
  const isToShipOnANonShippingDay = allowSaturdayShipping
    ? isSunday( startOfShipOn )
    : isWeekend( startOfShipOn )
  const toShipOn = useMemo(
    () => ( isToShipOnANonShippingDay ? nextMonday( startOfShipOn ) : startOfShipOn ),
    [ isToShipOnANonShippingDay, startOfShipOn ]
  )
  const toShipOnAsString = useMemo(() => dateForManifest( toShipOn ), [ toShipOn ])

  const activeFacilities =
    shippingFacilities?.filter(
      ( x ) => !x.deletedAt || isBefore( startOfShipOn, parseISO( x.deletedAt ))
    ) || []

  const withFacilityParams = useCallback(
    ( facilityInput: IFacilityInput | null = null ): Search => {
      if ( !canUseMultiFacility ) return ''
      const searchParams = new URLSearchParams()
      if ( facilityInput?.facilityId && facilityInput.facilityType ) {
        searchParams.set( 'facility_id', facilityInput.facilityId )
        searchParams.set( 'facility_type', facilityInput.facilityType )
      } else if ( facilityId && facilityType ) {
        searchParams.set( 'facility_id', facilityId )
        searchParams.set( 'facility_type', facilityType )
      }
      return searchParams.toString()
    },
    [ canUseMultiFacility, facilityId, facilityType ]
  )

  /**
   * Method to return all possible link Urls that match routes in this app.
   * Keep this in sync with the routes as much as possible. (See markup toward the end of the App.tsx file.)
   * @param {String} shipDateArg An ISO-formatted date string. (Ex. '2022-06-26')
   * @returns {Object} A list of URLs with keys named after relevant sections/pages.
   */
  const makeLinkUrls = useCallback(
    ( shipDateArg: string = toShipOnAsString ) => {
      const baseLoggedInUrl = `/${mdashAccountId}`
      const labelsBase = `${baseLoggedInUrl}/labels/${shipDateArg}`
      const manifestBase = `${baseLoggedInUrl}/manifest`

      return {
        login: '/login',
        manifestBase,
        manifest: `${manifestBase}/${shipDateArg}/`,
        subproducts: `${baseLoggedInUrl}/subproducts/${shipDateArg}`,
        whatsNew: `${baseLoggedInUrl}/whats_new`,
        discardLabels: `${labelsBase}/discard`,
        reprintLabels: `${labelsBase}/reprint`,
        confirmReprintLabels: `${labelsBase}/confirm_reprint`,
        printLabels: `${labelsBase}/print`,
        printFulfillmentSheets: `${baseLoggedInUrl}/fulfillment_sheets/${shipDateArg}`,
        printPickList: `${baseLoggedInUrl}/pick_list/${shipDateArg}`,
        checkout: ( id: string ) => `${baseLoggedInUrl}/checkout/${id}`,
        packageDetail: ( id: string ) => `${baseLoggedInUrl}/package/${id}`,
        reportDetail: ( id: string ) => `${baseLoggedInUrl}/report/${id}`,
        productSummary: `${baseLoggedInUrl}/summary/${shipDateArg}`,
        settings: `${baseLoggedInUrl}/settings`,
      }
    },
    [ mdashAccountId, toShipOnAsString ]
  )

  return {
    userId,
    mdashAccountId,
    mdashAccountName,
    mdashAccountPermissions,
    merchantUserPermissions,
    defaultMerchantId,
    displayPackageBarcode,
    displaySku,
    useDryIcePlaceholder,
    packingSlipVersion,
    isAdmin,
    isMultiPickUpLocation,
    isMultiShippingFacility,
    isPickUpEnabled,
    activeFacilities,
    canUseMultiFacility,
    facilityId,
    isTangibleFacility,
    facilityType,
    pauseForFacility,
    withFacilityParams,
    toShipOn,
    toShipOnAsString,
    packageSort,
    setPackageSort,
    makeLinkUrls,
    isToShipOnANonShippingDay,
    showStatements,
    reportLinks,
    allowSaturdayShipping,
  }
}

export default useAppParams
